import React, { useEffect, useState } from "react";

import { useStore } from "../../store";
import { AcademicCapIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import {
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon,
    CheckCircleIcon,
    ExclamationCircleIcon
} from "@heroicons/react/20/solid";


export const UserMspTab = (props) => {
    const { tenant_uid, tenants, showToast, onContext } = props

    const {usr_info} = useStore(x => x)

    const [state, setState] = useState({
        user_lookup: {},
    })
    const { user_lookup } = state

    useEffect(() => {
        Util.fetch_js('/msp/dashboard/', {tenant_uid: usr_info.tenant.uid},
            js => {
                setState(prev => ({...prev,
                    user_lookup: js.users,
                }));
            }, showToast)
    }, [])

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">
                        Tenant User Overview
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        All of your tenants and user details
                    </p>
                </div>
            </div>

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900">
                                    Tenant
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    <div className="whitespace-nowrap inline-flex">
                                        <CheckCircleIcon className="h-5 w-5 mr-2 text-green-600"/>
                                        {Language.getSentenceCase('completed')}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    <div className="whitespace-nowrap inline-flex">
                                        <ArrowTrendingUpIcon className="h-5 w-5 mr-2 text-blue-600"/>
                                        {Language.getSentenceCase('on track')}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    <div className="inline-flex whitespace-nowrap">
                                        <ArrowTrendingDownIcon className="h-5 w-5 mr-2 text-orange-600"/>
                                        {Language.getSentenceCase('off track')}
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {Language.getSentenceCase('engagement')}
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {tenants.map((tenant, idx) => (
                                <tr key={`tenant_row_${idx}`} className='cursor-pointer'
                                    onClick={() => onContext(tenant.uid)}>
                                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                        <div className="flex items-center">
                                            <div className="size-11 shrink-0 bg-gray-50">
                                                <img alt="" src={tenant.compact_logo} className="size-11 rounded-full" />
                                            </div>
                                            <div className="ml-4">
                                                <div className="font-medium text-gray-900">
                                                    {Util.truncate(tenant.name, 24)}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        {user_lookup[tenant.uid]?.completed || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        {user_lookup[tenant.uid]?.active || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        {user_lookup[tenant.uid]?.inactive || '-'}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                        {Util.engaged( user_lookup[tenant.uid]?.percentage )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

