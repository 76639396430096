import React, {useEffect, useRef, useState} from "react";
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from 'react-dom/client';

import { Loading } from "./components/loading";
import { Login } from "./pages/login";
import { Register } from "./pages/register";
import { Resetpwd } from "./pages/resetpwd";
import { AdminMain } from "./pages/admin_main";
import { UserMain } from "./pages/user_main";
import { PhishClickThrough } from "./pages/phish_click_through";
//import PuzzleGame3d from "./components/games/puzzleGame3d";
import {CrashTest} from "./pages/crash_test";
import { Onboarding } from "./pages/onboarding/onboarding";


import { AuthenticationType, PushTokenState } from "./helpers/consts";

// SSO
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";

import { useStore } from "./store";
import Util from "./helpers/util";
import { setTenantColors } from "./helpers/content";

// Sentry.io
import * as Sentry from "@sentry/react";
import { SENTRY_DSN, SENTRY_ENV, STRIPE_PK } from "./settings";
import { loadStripe } from "@stripe/stripe-js";


//Azure tutorial for SSO
//https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react

//Setup stripe
const stripePromise = loadStripe(STRIPE_PK);

// Sentry.io error reporting
Sentry.init({
    dsn: SENTRY_DSN,
    //integrations: [
    //new Sentry.BrowserTracing({
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/.*drip7.*/],
    //}),
    //new Sentry.Replay(),
    //],
    // Performance Monitoring
    tracesSampleRate: 1.0,            // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1,    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: SENTRY_ENV,          // Name of the environment you deployed your code to
});

export const App = (props) => {
    const { csrf, auth_type, tenant, tenant_uid, public_mode, initial_path, initial_args } = props;
    const { setCsrfIfEmpty, setUsrInfo, usr_info } = useStore(state => state)

    const [state, setState] = useState({
        loaded: false,
        err: false,
        push_token_state: PushTokenState.NONE,
        initial_tenant: {},
    })
    const { loaded, err, push_token_state, initial_tenant } = state

    const navigate = useRef( useNavigate() )

    useEffect(() => {
        window.storePushToken = handlePushToken
        window.getSubdomain = handleGetSubdomain

        //Set the stripe promise
        useStore.setState({ stripePromise });

        //Assign the csrf token if I was given one
        if ( csrf.indexOf( "csrf_token" ) < 0 ) {
            useStore.setState( { csrf_token: csrf } );
        }

        //If we already have a tenant, don't overwrite it
        let initial_tenant = tenant.uid ? tenant : {}
        if ( !tenant.uid && tenant.indexOf( "{{ tenant }}" ) < 0 && tenant != "" ) {
            try {
                const js = tenant.replaceAll( "'", '"' ).replaceAll( "True", "true" ).replaceAll( "False", "false" )
                initial_tenant = JSON.parse( js )

                Util.setFavicon( initial_tenant.favicon )
            } catch ( e ) {
                console.log( tenant )
                console.log( e )
            }
        }

        if ( public_mode.indexOf( "public_mode" ) < 0 && public_mode != "" ) {
            console.log( `Args is being called *${public_mode}*` )
            useStore.setState( { public_mode: true } );
        }

        if ( initial_args.indexOf( "initial_args" ) < 0 && initial_args != "" ) {
            console.log( `Args is being called *${initial_args}*` )
            useStore.setState( { initial_args } );
        }

        //Do we have a starting location?
        if ( initial_path.indexOf( "initial_path" ) < 0 && initial_path != "" ) {
            console.log( `This is being called *${initial_path}*` )
            useStore.setState( { initial_path } );
            navigate.current( initial_path );
        }

        Util.fetch_js( "/human/is_valid/", {},
            js => {
                setCsrfIfEmpty(js.csrf); //Assign a csrf? This is only done if we are in dev mode on the server

                //Store some user info
                if (js.valid) {
                    const my_data = {
                        ...js.usr_info,
                        msp: js.msp,
                        tenant: js.tenant
                    };
                    setUsrInfo(my_data);

                    //If we're in a react-native app, send a message that the user is logged in
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(js.usr_info);
                    }

                    setTenantColors( my_data.tenant )

                    Util.setFavicon( js.tenant.favicon )

                    //Goto my portal
                    if (window.location.pathname == "/") {
                        navigate.current(js.usr_info.portal);
                    }
                }
                else if ( initial_path == "" || initial_path == "{{ initial_path }}" ) {
                    if ( window.location.pathname != "/register" &&
                         window.location.pathname != "/resetpwd" &&
                         window.location.pathname != "/onboarding" ) {
                        navigate.current("/login")
                    }
                }
                else {
                    const my_data = {
                        email: "",
                        role: "normal",
                        msp: {},
                        tenant: initial_tenant,
                    };
                    setUsrInfo(my_data);
                }

                setState(prev => ({...prev,
                    loaded: true
                }))
            },
          (err, code) => {
                setState(prev => ({...prev,
                    loaded: true,
                    err: true
                }))
            }
        );

        attachAiProfiler()

        setState(prev => ({...prev,
            initial_tenant
        }))
    }, [])

    const handleGetSubdomain = () => {
        if ( 'tenant' in usr_info && 'subdomain' in usr_info.tenant ) {
            return usr_info.tenant.subdomain
        }

        return ""
    }

    const handlePushToken = (token) => {
        console.log('handlePushToken: ' + token)

        switch (push_token_state) {
            case PushTokenState.NONE:
                //Send the push token
                Util.fetch_js( "/human/store_push_token/", { token },
                    js => {
                        if (js.valid) {
                            setState(prev => ({...prev,
                                push_token_state: PushTokenState.TOKEN_SUCCESS
                            }))
                        }
                        else {
                            setState(prev => ({...prev,
                                push_token_state: PushTokenState.TOKEN_FAILED
                            }))
                        }
                    },
                    (err, code) => {
                        console.log('PushTokenError: ' + err);
                    }
                );

                //Set the next state
                setState(prev => ({...prev,
                    push_token_state: PushTokenState.SYNCING_TOKEN
                }))
                return false;

            //This means we've stored the token correctly
            case PushTokenState.TOKEN_SUCCESS:
            case PushTokenState.TOKEN_FAILED:
                return true;

            //All of this means we aren't done
            case PushTokenState.SYNCING_TOKEN:
            default:
                return false;
        }
    }

    const coord = useRef({ x: 0, y: 0, tick: 0})
    const attachAiProfiler = () => {

        document.addEventListener("mousemove", (e) => {
            const tick = new Date().getTime()
            //console.log(`${Math.abs(e.clientX - coord.current.x)}, ${Math.abs(e.clientY - coord.current.y)} ${tick - coord.current.tick}`)
            coord.current.x = e.clientX
            coord.current.y = e.clientY
            coord.current.tick = tick
        })

        document.addEventListener("click", (e) => {
            //console.log(`${e.clientX}, ${e.clientY} ${e.webkitForce}`)
        })

        document.addEventListener("keypress", (e) => {
            const tick = new Date().getTime()
            //console.log(`${e.keyCode}, ${tick - coord.current.tick}`)
            coord.current.tick = tick
        })
    }

    if (!loaded) {
        return <div></div>;
    }

    if (err) {
        return <Loading msg={"Couldn't communicate with the server"} />;
    }

    //<Route exact path="/" component={Login}/>
    return (
        <Routes>
            <Route exact path='/login' element={
                <Login
                    initial_tenant={initial_tenant}
                    auth_type={auth_type}
                    tenant_uid={tenant_uid}
                    />
            }/>
            <Route exact path='/onboarding' element={
                <Onboarding
                />
            }/>
            <Route exact path='/register' element={
                <Register
                    initial_tenant={initial_tenant}
                    />
            }/>
            <Route exact path='/resetpwd' element={
                <Resetpwd
                    initial_tenant={initial_tenant}
                    />
            }/>
            <Route path='/phish-click-through' element={
                <PhishClickThrough
                    showToast={() => { }}
                    onPathChange={path => this.setState({ path })}
                />
            }/>
            <Route path='/portal/*' element={
                <AdminMain />
            }/>
            {/*
            <Route path='/game' element={
                <PuzzleGame3d />
            }/>
            */}
            <Route path='/crash' element={
                <CrashTest />
            }/>
            <Route path='*' element={
                <UserMain />
            }/>
        </Routes>
    );
}

const app = document.getElementById('app');
const root = createRoot(app);

const csrf = app.getAttribute("csrf");
const public_mode = app.getAttribute("public_mode");
const initial_path = app.getAttribute("initial_path");
const initial_args =  app.getAttribute("initial_args");
//const initial_path = '/phish-click-through' // app.getAttribute("initial_path");
//const initial_args =  '15830fb3-3a3b-418f-b317-092d93357042' //app.getAttribute("initial_args");

var msalInstance = null;
// Get the domain to figure out the tenant
const domain = window.location.host.split(".")[0];
console.log( domain )
console.log( domain.toLowerCase().startsWith("localhost") )
// If the domain is v2 or localhost (debugging), default the domain to drip7
Util.fetch_js( "/tenant/login_details/", { domain: domain.toLowerCase().startsWith("localhost") ? "play" : domain },
    js => {
        //Store some user info
        if (js.successful) {
            setTenantColors( js.tenant )
        }

        msalInstance = (js.successful && js.tenant.auth_type == AuthenticationType.AZURE_SSO && js.tenant.tenant_sso)
                ? new PublicClientApplication(js.tenant.tenant_sso.auth_config)
                : null;

        const tenant = js.tenant || app.getAttribute("tenant");

        //*** This is the part that inserts the react app into the dom
        root.render(
            <BrowserRouter>
                {msalInstance ? (
                    <MsalProvider instance={msalInstance}>
                        <App
                            csrf={csrf}
                            tenant={tenant}
                            initial_args={initial_args}
                            initial_path={initial_path}
                            public_mode={public_mode}
                            auth_type={js.tenant.auth_type}
                            tenant_uid={js.tenant.uid}
                        />
                    </MsalProvider>
                ) : (
                    <App
                        csrf={csrf}
                        tenant={tenant}
                        initial_args={initial_args}
                        initial_path={initial_path}
                        public_mode={public_mode}
                        auth_type={js.tenant.auth_type}
                        tenant_uid={js.tenant.uid}
                    />
                )}
            </BrowserRouter>
        );
    },
    (err, code) => {
        console.log(err);
    }
);
