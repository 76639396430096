import React, { useEffect, useState } from "react";

import { useStore } from "../../store";
import { AcademicCapIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Progress } from "../../components/progress";


export const QueueMspTab = (props) => {
    const { tenant_uid, tenants, isPolicy, showToast, onContext } = props

    const {usr_info} = useStore(x => x)

    const [state, setState] = useState({
        queue_lookup: {},
        user_lookup: {},
        answer_lookup: {},
    })
    const { queue_lookup, user_lookup, answer_lookup } = state

    useEffect(() => {
        Util.fetch_js('/msp/queue/', { tenant_uid: usr_info?.tenant?.uid, is_policy: isPolicy },
            js => {
                const queue_lookup = {}
                js.queue_tenants.forEach(qt => {
                    if ( !(qt.tenant_uid in queue_lookup) ) {
                        queue_lookup[qt.tenant_uid] = []
                    }
                    queue_lookup[qt.tenant_uid].push(qt)
                })

                console.log(js.answered)
                setState(prev => ({...prev,
                    queue_lookup,
                    user_lookup: js.users,
                    answer_lookup: js.answered,
                }));
            }, showToast)
    }, [])

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">
                        Tenants
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        All of your tenants and their engagement.
                    </p>
                </div>
            </div>

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900">
                                    Tenant
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Training
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {Language.getSentenceCase('engagement')}
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {tenants.map((tenant, idx) => (
                                <tr key={`tenant_row_${idx}`} className='cursor-pointer'
                                    onClick={() => onContext(tenant.uid)}>
                                    <td className="flex px-3 py-3">
                                        <div className="flex items-center">
                                            <div className="size-11 shrink-0 rounded-full bg-drip7">
                                                <img alt="" src={tenant.compact_logo} className="size-11 rounded-full" />
                                            </div>
                                            <div className="ml-4">
                                                <div className="font-medium text-gray-900">{tenant.name}</div>
                                                <div className="mt-1 text-gray-500 flex flex-row">
                                                    {tenant.has_content_creation &&
                                                        <AcademicCapIcon className="size-4 text-drip7"/>
                                                    }
                                                    {tenant.has_document_acceptance &&
                                                        <DocumentCheckIcon className="ml-2 size-4 text-drip7"/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                                        {queue_lookup[tenant.uid] && queue_lookup[tenant.uid].map(q => (
                                            <div key={`queue_${q.uid}`} className="mb-2">
                                                <Progress
                                                    label={Util.truncate(q.name, 32)}
                                                    label_only={true}
                                                    idx={q.delivered_questions}
                                                    total={q.total_questions}
                                                />
                                            </div>
                                        ))}
                                    </td>
                                    <td className="flex whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                                        {Util.engaged( user_lookup[tenant.uid]?.percentage )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

