import React, { useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Input } from "../../components/drip7_input";

export const TenantModal = props => {
    const { showToast, super_admin, tenant_uid, onClose } = props;
    const [state, setState] = useState({
        name: '',
        demo: false
    });
    const { name, demo } = state;

    const handleChange = e => {
        setState({ ...state, [e.target.id]: e.target.value });
    };

    const handleChecked = e => {
        setState({ ...state, [e.target.id]: e.target.checked });
    }

    const handleSave = () => {
        if (name == '')
            return;
        
        //Create the tenant
        Util.fetch_js( "/tenant/create/", { name, demo, tenant_uid },
            js => {
                onClose(js);
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    };

    return (
        <div className='basic-modal basic-modal--add-question' tabIndex={-1}>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content starts')}</p>
            <div className='basic-modal__head'>
                <h2>{Language.getTitleCase('tenant')}</h2>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={() => onClose()}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className='basic-modal__field-container basic-modal__field-container--add-question'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('name')}
                    </label>
                    <Drip7Input
                        placeholder={Language.getTitleCase('Tenant name')}
                        id='name'
                        value={name}
                        onChange={handleChange}
                    />
                </div>
                <div className='basic-modal__field basic-modal__field--add-question'></div>

                {super_admin && (
                    <div className='customization-checkbox'>
                        <input
                            type='checkbox'
                            aria-label="checkbox"
                            name='demo'
                            onChange={handleChecked}
                            id='demo'
                            checked={demo}
                        />
                        <span className='checkmark'></span>
                        <span className='tool-tip__container'>
                            <span className="tool-tip__text">
                                {Language.getSentenceCase("when checked, this new tenant is a 30-day trial")}
                            </span>
                        <label htmlFor='demo'>
                            {Language.getTitleCase('30-day trial')}
                        </label>
                        </span>
                    </div>
                )}
            </div>

            <div className='basic-modal__button-container'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={() => onClose()}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>
        </div>
    );
};
