import { PlusIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Drip7Button } from "./drip7_button";

export const EmptyList = props => {
    const { icon, name, onCreate } = props
    const title = props.title || `No ${name}`
    const description = props.description || `Get started by creating a new ${name}.`

    const visual = { icon }

   return (
       <div className="text-center mt-12">
           <visual.icon className="mx-auto h-12 w-12 text-gray-400"/>

           <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
           <p className="mt-1 text-sm text-gray-500">{description}</p>
           {onCreate !== undefined && (
           <div className="mt-6">
               <Drip7Button
                   type="button"
                   className="inline-flex items-center "
                   onClick={onCreate}>
                   <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                   Create {name}
               </Drip7Button>
           </div>
           )}
       </div>
   )
}