import React, { useEffect, useState } from "react";
import { useStore } from "../store";
import Util from "../helpers/util";
import { Toast } from "../components/toast";
import { DripQuestions } from "../components/user/drip_questions";
import '../assets/scss/layout/_phish_click_through.scss';
import headerScribble from "../assets/images/phishing/scribble1.png";
import columnScribble from "../assets/images/phishing/scribble2.png";
import seeSomethingScribble from "../assets/images/phishing/scribble3.png";
import reviewScribble from "../assets/images/phishing/scribble4.png";
import addressedTo from "../assets/images/phishing/addressedTo.png";
import addressesConsistent from "../assets/images/phishing/addressesConsistent.png";
import detailEmail from "../assets/images/phishing/detail.png";
import hidingBehindLinks from "../assets/images/phishing/hidingBehindLinks.png";
import badge from "../assets/images/phishing/badge.png";
import Language from "../helpers/language";
import { UserInteractionDetection } from "../components/user/user_interaction_detection";

export const PhishClickThrough = props => {
    const { initial_path, initial_args, usr_info, setUsrInfo } = useStore(
        state => state
    );
    const { profile_url, nickname, name, email, role, points, tenant } = usr_info;
    const [state, setState] = useState({
        phish: {},
        contents: [],
        follow_ups: [],
        completed: false,
        toast: { msg: "", status: "" },
        detected: false,
    });
    const { phish, contents, completed, follow_ups, toast, detected } = state;

    const interactionRef = React.createRef();

    //Load phishing information
    useEffect(() => {
        document.title = 'You Got Phished | Drip7'
        Util.fetch_js( "/client/question/get_phish/", { user_question_uid: initial_args },
            js => {
                setState(prev => ({ ...prev,
                    phish: js.question,
                    question_contents: js.question_contents,
                    follow_ups: js.follow_up_questions
                }))
            },
            (reason, code) => {
                handleToast(reason, "failure");
            }
        )
    }, []);

    const handleOnComplete = () => {
        setState(prev => ({ ...prev, completed: true }))
    };

    const handleToast = (msg, status) => {
        const toast = { msg, status };
            setState({ ...state, toast });
    };

    const handleToastComplete = () => {
        const toast = { msg: "", status: "" };
        setState({ ...state, toast });
    };

    const handleTouchStart = e => {
        if ( interactionRef.current ) {
            interactionRef.current.handleTouchStart( e )
        }
    }

    const handleTouchEnd = e => {
        if ( interactionRef.current ) {
            interactionRef.current.handleTouchEnd( e )
        }
    }

    const detectedUserInteraction = event => {
        if ( detected ) {
            return
        }

        Util.fetch_js( "/phish/detected_user_interaction/",
            { user_question_uid: initial_args },
            js => {
                console.log( "User interaction detection logged" )
            },
            (reason, code) => {
                handleToast(reason, "failure");
            })

        setState(prev => ({ ...prev, detected: true }))
    }

    return (
        <div className='phishing-page flex-col justify-center'
             onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd}>

            <UserInteractionDetection
                once={true}
                ref={interactionRef}
                onUserInteraction={detectedUserInteraction}
            />

            <header className="user-header" style={{ height: '82px' }}>
                <div className="user-header__logo h-20">
                    {tenant.compact_logo ? (
                        <img
                            alt="compact logo"
                            src={tenant.compact_logo}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}/>
                    ) : (
                        <svg
                            className='d7-logo-white'
                            width='164'
                            height='89'
                            viewBox='0 0 164 89'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <g clipPath='url(#clip0)'>
                                <path
                                    d='M43.8085 59.679H32.185V53.6032C29.2945 57.8334 24.887 60.1352 19.27 60.1352C7.667 60.1352 0 51.6126 0 38.8598C0 26.2729 7.585 17.8954 18.983 17.8954C24.764 17.8954 29.233 20.1971 32.2055 24.4273V2.69574H43.829V59.679H43.8085ZM32.2055 39.1708C32.2055 32.4108 28.0235 27.7244 22.0375 27.7244C15.9695 27.7244 11.7875 32.4937 11.7055 39.1708C11.7875 45.9308 15.8875 50.6172 22.0375 50.6172C28.0235 50.6172 32.2055 45.9308 32.2055 39.1708Z'
                                    fill='currentColor'></path>
                                <path
                                    d='M61.623 25.8166C64.4315 20.757 69.0645 17.9783 75.1325 17.8954V29.0307C66.789 28.2635 61.623 32.784 61.623 39.7929V59.6789H49.938V18.3516H61.623V25.8166Z'
                                    fill='currentColor'></path>
                                <path
                                    d='M91.9015 6.61486C91.9015 10.4511 89.3185 13.2297 85.608 13.2297C81.8975 13.2297 79.3145 10.4718 79.3145 6.61486C79.3145 2.77866 81.8975 0 85.608 0C89.3185 0 91.9015 2.75792 91.9015 6.61486ZM79.7655 18.3516H91.4505V59.6789H79.7655V18.3516Z'
                                    fill='currentColor'></path>
                                <path
                                    d='M152.602 31.6021H147.498L158.608 7.07109H147.498L147.457 7.05035H143.213V2.69574H164V6.40753L152.602 31.6021Z'
                                    fill='currentColor'></path>
                                <path
                                    d='M122.098 17.8954C116.563 17.8954 112.074 20.1971 109.183 24.3444V18.3516H97.498V64.2616C97.498 65.0911 97.6825 65.9205 98.0105 66.667C98.3385 67.4135 98.7485 67.8905 99.3635 68.4089C100.553 69.4042 101.619 70.6484 102.049 72.1621C102.48 73.7174 102.152 75.4592 101.229 76.8071C100.532 77.8231 99.5685 78.5696 98.7895 79.5028C97.9285 80.5188 97.498 81.7838 97.498 83.1109C97.498 86.3665 100.102 89 103.32 89C106.539 89 109.142 86.3457 109.142 83.1109C109.142 81.5971 108.589 80.2285 107.543 79.1917C106.518 78.1757 105.432 77.1596 104.837 75.791C104.14 74.1736 104.284 72.2036 105.247 70.7313C105.924 69.6738 106.928 68.8858 107.769 67.9734C108.691 66.9366 109.163 65.6924 109.163 64.2824V53.6239H109.204C112.176 57.7712 116.645 60.0729 122.344 60.0729C133.804 60.0729 141.327 51.6955 141.327 39.1708C141.307 26.418 133.558 17.8954 122.098 17.8954ZM119.351 50.2232C113.345 50.2232 109.183 45.6197 109.183 38.7768C109.183 32.0997 113.365 27.4133 119.351 27.4133C125.358 27.4133 129.458 32.1827 129.458 38.7768C129.458 45.5368 125.358 50.2232 119.351 50.2232Z'
                                    fill='currentColor'></path>
                            </g>
                            <defs>
                                <clipPath id='clip0'>
                                    <rect
                                        width='164'
                                        height='89'
                                        fill='currentColor'></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                </div>
                <div className="user-header__mini-profile user-mini-profile">
                    <div className='user-profile-photo' style={{ width: "58px", height: "58px"}}>
                        <img
                            src={profile_url}
                            alt='user profile w-32 h-32'
                            style={{
                                width: "64px",
                                height: "64px",
                                marginTop: "-4px"
                            }}
                        />
                    </div>
                    <div className='user-mini-info'>
                        <h3 className='user-name' style={{color: 'white'}}>{nickname || name}</h3>
                    </div>
                </div>
            </header>

            <div className='container ml-auto' style={{padding: '108px 0'}}>
                <h2>Oooooooops!</h2>
                <div className='squiggly-separator'>
                    <span>
                    <img className='img-fluid' alt='separator' src={headerScribble} />
                    </span>
                </div>
                <p className="mb-8">
                    {name}, you took the bait.
                </p>
                <p>You've been phished. The <b>{ tenant.name }</b> IT program sent this simulated phishing email to test your knowledge.  If this phishing email were a real attack, your information could now be in the hands of cybercriminals. Fortunately, that's not the case this time.</p>
                <p>Let's take a moment to review what you missed in the phishing email so that next time you don't take the bait.</p>
                <p>Answering the following questions can help identify a phishing email.</p>
            </div>
            <div className='wavy-pattern'></div>
            <div className='blue-background' style={{padding: '54px 0'}}>
                <div className='container ml-auto'>
                    <div className='row row-cols-lg-4 row-cols-2'>
                        <div className='flex-column d-flex'>
                            <h4>Who is it addressed to?</h4>
                            <div className='squiggly-separator'>
                                <span>
                                    <img className='img-fluid' alt='separator' src={columnScribble} />
                                </span>
                            </div>
                            <p>Was it addresed to you or to someone else? Was the greeting generic?</p>
                            <div className='mt-auto'>
                                <img className='img-fluid' alt='separator' src={addressedTo} />
                            </div>
                        </div>
                        <div className='mt-12 flex-column d-flex'>
                            <h4>Are the addresses consistent?</h4>
                            <div className='squiggly-separator'>
                                <span>
                                    <img className='img-fluid' alt='separator' src={columnScribble} />
                                </span>
                            </div>
                            <p>Do the email signature, company name, and URL all match?</p>
                            <div className='mt-auto'>
                                <img className='img-fluid' alt='separator' src={addressesConsistent} />
                            </div>
                        </div>
                        <div className='mt-12 flex-column d-flex'>
                            <h4>What's hiding below that link?</h4>
                            <div className='squiggly-separator'>
                                <span>
                                    <img className='img-fluid' alt='separator' src={columnScribble} />
                                </span>
                            </div>
                            <p>Does the link go to a page that would be expected from other info in the email?</p>
                            <div className='mt-auto'>
                                <img className='img-fluid' alt='separator' src={hidingBehindLinks} />
                            </div>
                        </div>
                        <div className='mt-12 flex-column d-flex'>
                            <h4>How much detail is there?</h4>
                            <div className='squiggly-separator'>
                                <span>
                                    <img className='img-fluid' alt='separator' src={columnScribble} />
                                </span>
                            </div>
                            <p>Phishing emails don't have to be long and detailed. Sometimes short emails hide a lot.</p>
                            <div className='mt-auto'>
                                <img className='img-fluid' alt='separator' src={detailEmail} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-20 ml-auto sm:pr-52'>
                <h2>If you see something, say something</h2>
                <div className='squiggly-separator'>
                    <span>
                        <img className='img-fluid' alt='separator'  src={seeSomethingScribble} />
                    </span>
                </div>
                <p>
                    Reporting a potential phish helps the whole organization stay secure. But there are steps we can take before reporting every unexpected (or undesired) email. Take the time to go through these questions before clicking links, downloading or opening files, and responding to emails. A moment of pause can save hours (and days) of headaches and other losses.
                </p>
                {follow_ups.length > 0 &&
                    <section style={{ padding: '54px 0' }}>
                        <h4 style={{ color: '#fa7445' }}>Earn back some points</h4>
                        <p>
                            You have been awarded some bonus questions that will help earn back some missed points.
                        </p>
                        <div className='squiggly-separator'>
                            <img className='img-fluid' alt='separator' src={reviewScribble} />
                        </div>
                        {!completed && (
                            <DripQuestions
                                user_questions={follow_ups}
                                showToast={handleToast}
                                onCompleted={handleOnComplete}
                            />
                        )}
                        <div style={{ display: (completed ? 'block': 'none'), alignItems: 'center' }}>
                            <p>Congratulations, you don't have any review
                                questions to answer.</p>
                        </div>
                    </section>
                }
            </div>
            <div className='footer image'>
                <div className='footer-wave'></div>
                <div className='container ribbon-badge'>
                    <img src={badge} alt="badge"/>
                </div>
            </div>

            <Toast
                msg={toast.msg}
                status={toast.status}
                timeout='5000'
                onComplete={handleToastComplete}
            />
        </div>
    );
};
