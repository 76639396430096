import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";

import {DocumentAcceptanceTab} from "./document_acceptance_tab";
import {DocumentCompletionTab } from "./document_completion_tab";
import { DocumentCheckIcon } from "@heroicons/react/24/solid";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import {QueueTab} from "./queue_tab";
import { useStore } from "../../store";
import { QueueMspTab } from "./queue_msp_tab";

export const DocumentAcceptance = (props) => {
    const { tenant_uid, tenants, onContext, showToast } = props;

    const {usr_info} = useStore(x => x)
    const { is_msp } = usr_info.tenant

    const [state, setState] = useState({
        selected_idx: is_msp? 0: 1
    })
    const { selected_idx } = state

    useEffect(() => {
        props.onPathChange("policies")
    }, [])

    return (
        <div className='questions main-modal'>
            <nav className='central__nav'>
                <ul className='central__nav-list'>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx === 0? "central__nav-item--selected": '')}
                        onClick={() => setState(prev => ({...prev, selected_idx: 0 }))}>
                        <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill='var(--drip7-primary-color)' d="M22.2187 5.39003C23.332 4.86999 24.5937 4.86999 25.707 5.39003L41.9609 12.8935C42.5547 13.1906 43 13.7849 43 14.4536C43 15.1965 42.5547 15.7908 41.9609 16.088L25.707 23.5914C24.5937 24.1114 23.332 24.1114 22.2187 23.5914L5.96484 16.088C5.37109 15.7908 5 15.1965 5 14.4536C5 13.7849 5.37109 13.1906 5.96484 12.8935L22.2187 5.39003ZM41.9609 22.4027C42.5547 22.6999 43 23.2942 43 23.9629C43 24.7058 42.5547 25.3001 41.9609 25.5973L25.707 33.1007C24.5937 33.6207 23.332 33.6207 22.2187 33.1007L5.96484 25.5973C5.37109 25.3001 5 24.7058 5 23.9629C5 23.2942 5.37109 22.6999 5.96484 22.4027L9.97266 20.5455L21.2539 25.7458C22.9609 26.563 24.9648 26.563 26.6719 25.7458L37.9531 20.5455L41.9609 22.4027ZM26.6719 35.2551L37.9531 30.0547L41.9609 31.912C42.5547 32.2092 43 32.8035 43 33.4721C43 34.2151 42.5547 34.8094 41.9609 35.1065L25.707 42.61C24.5937 43.13 23.332 43.13 22.2187 42.61L5.96484 35.1065C5.37109 34.8094 5 34.2151 5 33.4721C5 32.8035 5.37109 32.2092 5.96484 31.912L9.97266 30.0547L21.2539 35.2551C22.9609 36.0723 24.9648 36.0723 26.6719 35.2551Z" />
                        </svg>
                        {Language.getTitleCase('msp')}
                    </li>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx === 1 ? "central__nav-item--selected" : "")}
                        onClick={() => setState( prev => ({
                            ...prev,
                            selected_idx: 1
                        }) )}>
                        <svg width='15' height='12' viewBox='0 0 15 12'
                             fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z'
                                fill='currentColor'></path>
                        </svg>
                        {Language.getTitleCase( 'Completion' )}
                    </li>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx === 2 ? "central__nav-item--selected" : "")}
                        onClick={() => setState( prev => ({
                            ...prev,
                            selected_idx: 2
                        }) )}>
                        <DocumentCheckIcon className='h-5 w-5'/>
                        {Language.getTitleCase( 'policies' )}
                    </li>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx === 3 ? "central__nav-item--selected" : "")}
                        onClick={() => setState( prev => ({
                            ...prev,
                            selected_idx: 3
                        }) )}>
                        <Square3Stack3DIcon className='h-5 w-5'/>
                        {Language.getTitleCase( 'Delivery' )}
                    </li>
                </ul>
            </nav>

            {selected_idx === 0 &&
                <QueueMspTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    isPolicy={true}
                    onContext={onContext}
                    showToast={showToast}
                />
            }

            {selected_idx === 1 && (
                <DocumentCompletionTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                />)}
            {selected_idx === 2 && (
                <DocumentAcceptanceTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                />
            )}
            {selected_idx === 3 && (
                <QueueTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                    isPolicy={true}
                />
            )}
        </div>
    );
}
