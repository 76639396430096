import React, {useEffect, useState} from "react"

import Util from "../../helpers/util"
import Language from "../../helpers/language"

import { Drip7OriginalModal } from "../../modals/drip7_original_modal"
import {AdminLogModal} from "../../modals/admin/admin_log_modal";
import { Pagination } from "../../components/pagination";

export const AdminLogTab = (props) => {
    const { tenant_uid, showToast } = props

    const [state, setState] = useState({
        logs: [],
        log: null,
        search: '',
        page: 1,
        pagecount: 0,
        perpage: 1,
        total: 0
    })
    const { logs, log, search, page, pagecount, perpage, total } = state

    useEffect(() => {
        fetchLogs(0)
    }, [tenant_uid])

    const fetchLogs = (p) => {
        Util.fetch_js( '/admin_log/list/', { tenant_uid, page: p },
            js => {
                setState( prev => ({ ...prev,
                    logs: js.logs,
                    pagecount: js.pages,
                    perpage: js.perpage,
                    total: js.total
                }) )
            },
            (err, code) => {
                showToast( err, 'failure' )
            })
    }

    const handleChanged = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handlePageChange = (e) => {
        // const p = e.target.value
        setState(prev => ({ ...prev,
            page: e
        }))
        fetchLogs(e-1)
    }

    console.log('search', search)
    const filtered_logs = logs.filter( x => search == "" || x.name.toLowerCase().includes(search.toLowerCase()) || x.endpoint.toLowerCase().includes(search.toLowerCase())).map( x => {
        x.endpoint = x.endpoint.replace( /^https?:\/\/[^\/]+\//, '' )
        x.endpoint = x.endpoint.replace( /\/$/, '' )
        x.endpoint = x.endpoint.split('/').map(x => Util.capitalize(x)).join(' - ')
        return x
    })

    return (
        <div className='central__main central__main--questions'>
            <div className='central__head'>
                <div className='central__head-container'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('search by name, endpoint') + '...'}
                            id="search"
                            value={search}
                            onChange={handleChanged}
                        />
                    </div>
                </div>
                <Pagination
                    page={page}
                    per_page={perpage}
                    total={total}
                    onPage={handlePageChange}
                />
            </div>

            <table className='questions__table px-2'>
                <tbody>
                <tr className='questions__table-row questions__table-row--head'>
                    <th className='questions__table-head questions__table-head--name'>
                        <h5 className='questions__table-heading'>
                            {Language.getTitleCase('name')}
                        </h5>
                    </th>
                    <th className='questions__table-head'>
                        <h5 className='questions__table-heading'>
                            {Language.getTitleCase('endpoint')}
                        </h5>
                    </th>
                    <th className='questions__table-head'>
                        <h5 className='questions__table-heading'>
                            {Language.getTitleCase('Timestamp')}
                        </h5>
                    </th>
                </tr>

                {Object.entries(filtered_logs).map(([key, log]) => (
                    <tr className='questions__table-row questions__table-row--body'
                        key={"admin_log_key_" + key}>
                        <td className='questions__table-data questions__table-data--name'>
                            <h5 className='questions__table-text'
                                onClick={() => handleChanged({ target: { id: 'log', value: log }})}>
                                {log.name}
                            </h5>
                        </td>
                        <td className='questions__table-data'>
                            <h5 className='questions__table-text'
                                onClick={() => handleChanged({ target: { id: 'log', value: log }})}>
                                {log.endpoint}
                            </h5>
                        </td>
                        <td className='questions__table-data'>
                            <h5 className='questions__table-text'
                                onClick={() => handleChanged({ target: { id: 'log', value: log }})}>
                                {Util.friendlyDateTime( Util.epoch(log.created_on))}
                            </h5>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Drip7OriginalModal>
                {log != null && (
                    <AdminLogModal
                        tenant_uid={tenant_uid}
                        log={log}
                        showToast={showToast}
                        onClose={() => handleChanged({ target: { id: 'log', value: null }})}
                    />
                )}
            </Drip7OriginalModal>
        </div>
    )
}
